<template>
  <div class="auth0-callback">
    <div class="auth0-callback__content">
      <img src="@/assets/logo.png" class="auth0-callback__logo" alt="logo" />
      <h3>{{ $t('PROCESSING_LOGIN') }}</h3>
      <p>{{ $t('PLEASE_WAIT') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Auth0Callback',
  data() {
    return {
      error: null
    };
  },
  async mounted() {
    try {
      // Handle the Auth0 redirect callback
      await this.$auth0.handleRedirectCallback();

      // Get the target URL from appState or localStorage
      const appState = this.$auth0.loading.appState;
      const targetUrl = appState?.targetUrl || localStorage.getItem('targetUrl') || '/';

      // Clear stored target URL
      localStorage.removeItem('targetUrl');

      // Redirect to the target URL
      this.$router.push(targetUrl);
    } catch (error) {
      console.error('Auth0 callback error:', error);
      this.error = error;
      // Redirect to login page with error
      this.$router.push({
        name: 'login',
        query: { error: 'auth_error' }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.auth0-callback {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: url('~@/assets/background_registration.png');

  &__content {
    background-color: var(--secondary-bg-color);
    border-radius: 10px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
  }

  &__logo {
    width: 100px;
    margin-bottom: 24px;
    animation: pulse 1.5s infinite;
  }

  h3 {
    margin-bottom: 16px;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
</style>
